<template>
  <div class="col-md-10 offset-md-1">
    <h1 v-if="title">{{title}}</h1>
    <label v-if="!hideLabel" class="gallery-label mx-2">Gallery</label>
      <div class="gallery text-center d-flex flex-row mb-3 flex-wrap">
          <div class="gallery-image" v-for="(image, index) in localData.images" :key="index">
              <button class="btn btn-image btn-danger" @click="deletePic(image.id)">Delete</button>
              <img :src="image.url" />
          </div>
          <div class="new-image " @click="addPics">
              +
          </div>
          <input type="file" id="upload-pic" multiple class="d-none" @change="uploadPics" />
      </div>
  </div>
</template>
<script>
export default {
    name: 'GallerySimpleManager',
    props: {
        gallery: Object,
        hideLabel: Boolean,
        title: String,
        updateImages: Boolean
    },
    data () {
        return {
            localData: this.gallery || {images: [] },
            images: []
        };
    },
    methods: {
        addPics () {
            document.getElementById("upload-pic").click();
        },
        uploadPics () {
            event.target.files.forEach( (file) => {
                var newImage = {
                    url: URL.createObjectURL(file),
                    id: '_local_data_' + this.localData.images.length
                };
                this.localData.images.push(newImage);
                this.images.push({
                    file: file,
                    id: newImage.id
                });
            });
            this.$emit('input', 'gallery', this.images);
        },
        deletePic (id) {
            this.localData.images = this.localData.images.filter((image) => {
                return image.id !== id;
            });
            this.images = this.images.filter((file) => {
                return file.id !== id;
            });
            this.$emit('input', 'gallery', this.images);
        }
    },
    watch: {
        gallery (val) {
            this.localData = val || {images: []};
        }
    },
}
</script>
<style scoped>
.pic img {
    width: 200px;
    border-radius: 150px;
}
.pic label {
    margin-top: -15px;
}
.gallery-image {
    width: 200px;
    height: 100px;
    position: relative;
    margin: 5px;
}
.gallery-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
div.new-image {
    width: 200px;
    height: 100px;
    line-height: 100px;
    font-size: 40px;
    color: gray;
    background: #f0f0f0;
    cursor: pointer;
    margin: 5px;
}
.btn-image {
    padding: 5px 20px;
    font-size: 10px;
    position: absolute;
}
.btn-image.btn-danger {
    right: 10px;
    top: 10px;
}
.btn-image.btn-primary {
    right: 10px;
    bottom: 10px;
}
</style>
