import { render, staticRenderFns } from "./GallerySimpleManager.vue?vue&type=template&id=3514f638&scoped=true&"
import script from "./GallerySimpleManager.vue?vue&type=script&lang=js&"
export * from "./GallerySimpleManager.vue?vue&type=script&lang=js&"
import style0 from "./GallerySimpleManager.vue?vue&type=style&index=0&id=3514f638&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3514f638",
  null
  
)

export default component.exports