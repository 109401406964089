<template>
  <div :class="{'service-selector':true, ...classes}">
    <div
      @click='select(item.id)'
      v-for='(item, index) in servicesList'
      :key='index'
      :class="{'item': true, 'selected': localSelected[item.id]}"
      >
      <img :src='item.image_icon' />
      <label>{{item.title}}</label>
    </div>
  </div>
</template>
<script>
import 'whatwg-fetch'

export default {
  name: 'SelectSFServices',
  props: {
    classes: Object,
    services: Array,
    selected: Object
  },
  data () {
    return {
      localSelected: {...this.selected},
      servicesList: [],
      loading: false
    };
  },
  watch: {
    selected (val) {
        this.localSelected = {...val};
    }
  },
  methods: {
    select (id) {
      this.$set(this.localSelected, id, !this.localSelected[id]);
      this.$emit('input', 'services', this.localSelected);
    }
  },
  created () {
    let self = this
    fetch("/api/service/").then(async function(result) {
        self.servicesList = (await result.json()).results;
    });
  }
}
</script>
<style scoped>
.service-selector div.title {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0px 20px 0px;
  line-height: 1.4em;
}
.service-selector {
  text-align: center;
}
.service-selector .item {
  display: inline-block;
  width: 40%;
  margin: 5%;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.service-selector .item img {
  width: 80%;
  padding: 10%;
}
.service-selector .item label {
  font-size: 0.9em;
}
.service-selector .item.selected {
  background: #ffd;
  border: 3px solid black;
}
</style>
