<template>
<div :class="{'simpleflex-column': true, ... classes}" :style="{ background: backgroundImg? 'url(' + backgroundImg + ')' : null, objectFit: 'cover' }">
  <div class="column-content">
    <slot></slot>
  </div>
</div>
</template>
<script>
export default {
  name: 'SimpleFlexRow',
  props: {
    classes: Object,
    backgroundImg: String
  }
}
</script>
<style>
.simpleflex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  position: relative;
}
.simpleflex-column.dark:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  z-index: 1;
}
.column-content {
  height: 100%;
  overflow-y: auto;
}
.simpleflex-column.dark .column-content {
  z-index: 2;
}

</style>
