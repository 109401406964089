<template>
  <label :class="{ 'dark': dark, 'mb-3': true, 'error': error != null, 'storyfaith-checkbox': true, ...classes }">
    <input v-model="localValue" type="checkbox" :id="(id? id:name) + '-input'" :placeholder="name"><div class="checkmark" /><span>{{name}}</span>
  </label>
</template>
<script>
export default {
  props: {
    value: Boolean,
    name: String,
    id: String,
    error: String,
    classes: Object,
    dark: Boolean
  },
  data () {
    return { localValue: this.value };
  },
  methods: {
  },
  watch: {
      value (val) {
          this.localValue = val;
      },
      localValue (val) {
           this.$emit('input', val);
      }
  }
}
</script>
<style>

.storyfaith-checkbox {
    display: inline-block;
    margin: 1em;
    position: relative;
    padding-left: 35px;
}

.storyfaith-checkbox span{
    font-size: 16px;
    cursor: pointer;
}

.storyfaith-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.storyfaith-checkbox .checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #222;
    display: inline-block;
    transition: background-color 0.5s;
    cursor: pointer;
}
.storyfaith-checkbox.dark .checkmark {
    border: 2px solid white;
    background-color: #222;
}
.storyfaith-checkbox:hover input ~ .checkmark {
    background-color: #aaa;
}
.storyfaith-checkbox input:checked ~ .checkmark {
    background-color: #222;
}
.storyfaith-checkbox.dark input:checked ~ .checkmark {
    background-color: white;
}


.storyfaith-checkbox.style-A {
  padding-left: 15px;
}
.storyfaith-checkbox.style-A .checkmark {
  height: 15px;
  width: 15px;
  left: -10px;
  top: -2px;
}
.storyfaith-checkbox.style-A span{
  font-size: 14px;
}
.storyfaith-checkbox.style-A.mb-5 {
  margin-bottom: 0px !important;
}
</style>
