import CheckBox from './CheckBox'
import CityQuery from './CityQuery'
import KeywordsField from './KeywordsField'
import Div from './Div'
import RankingField from './RankingField'
import Select from './Select'
import ScaleField from './Scale'
import TextArea from './TextArea'
import TextInput from './TextInput'
import TextSection from './TextSection'

export default {
  types: {
    'div': Div,
    'checkbox': CheckBox,
    'city': CityQuery,
    'keywords': KeywordsField,
    'plaintext': TextSection,
    'select': Select,
    'textarea': TextArea,
    'text': TextInput,
    'ranking': RankingField,
    'scale': ScaleField
  }
}
