<template>
<div :class="{
    'simpleflex-row-responsive': responsive,
    'simpleflex-row': true,
    'simpleflex-row-full': full,
    'simpleflex-row-fixed': fixed,
    ... classes
  }">
  <slot></slot>
</div>
</template>
<script>
export default {
  name: 'SimpleFlexRow',
  props: {
    classes: Object,
    full: Boolean,
    responsive: Boolean,
    fixed: Boolean
  }
}
</script>
<style>
.simpleflex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
}
.nowrap {
  flex-wrap: nowrap;
}
.simpleflex-row-full {
  flex-grow: 1;
}
.simpleflex-row-fixed {
  flex-shrink: 0;
}

@media(max-width: 750px) {
  .simpleflex-row-responsive {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
}

</style>
