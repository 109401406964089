import { getCookie } from './client'


class ClientPendingActions {

  constructor () {
    this.prefix = '__sf__client_pending_actions_'
    this.supportedTypes = [
      'SubmitSurveyResponse'
    ]

    this.store = window.sessionStorage
    this.actions = this.getPendingActions()
  }

  isTypeSupported(typeString) {
    return this.supportedTypes.indexOf(typeString) != -1
  }

  getPendingActions() {
    try {
      var items = JSON.parse(this.store.getItem(`${this.prefix}_items`))
    }
    catch {
      items = []
    }
    finally {
      if (!items) {
        items = []
      }
    }
    let initialCount = items.length
    let _this = this
    let now = Date.now()
    items = items.filter(item => item.expires && (new Date(item.expires)) > now && _this.isTypeSupported(item.type) && !item.resolved)
    if (items.length < initialCount)
      this.store.setItem(`${this.prefix}_items`, items)
    return items
  }

  syncActions() {
    this.store.setItem(`${this.prefix}_items`, JSON.stringify(this.actions))
  }

  findActionIndex(type, id) {
    return this.actions.map(item => item.type == type && item.id == id).indexOf(true)
  }

  deferAction(actionItem, replaceOk) {
    let actionIndex = this.findActionIndex(actionItem.type, actionItem.id)
    if (actionIndex > -1 && !replaceOk)
      throw 'Duplicate action at ClientPendingActions.deferAction(actionItem)'
    if (actionIndex == -1)
      this.actions.push(actionItem)
    else
      this.actions[actionIndex] = actionItem
    this.syncActions()
  }

  resolveAction(actionItem) {
    let actIndex = this.actions.map(item => item.type == actionItem.type && item.id == actionItem.id).indexOf(true)
    this.actions[actIndex].resolved = true
    this.syncActions()
  }

  isActionPending(actionItem) {
    let actIndex = this.actions.map(item => item.type == actionItem.type && item.id == actionItem.id).indexOf(true)
    return this.actions[actIndex].resolved === true
  }

  async handleAction(action) {
    if (action.resolved) return
    const csrftoken = getCookie('csrftoken');
    this.resolveAction(action)
    switch(action.type) {
      case 'SubmitSurveyResponse':
        // TODO: this comes from the SurveyLandinPage vue file, we must unify this logic in one place and import it from here
        var formData = new FormData()
        formData.append('response', JSON.stringify(action.data))
        if (action.metadata.community_fill) {
          formData.append('community', action.metadata.community_fill)
        }
        return await fetch("/api/account/survey/" + action.metadata.survey.slug + "/response/", {
            headers: {
                "X-CSRFToken": csrftoken
            },
            body: formData,
            method: "POST"
        })
    }
  }
}

export {
  ClientPendingActions
}
