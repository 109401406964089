<template>
  <div :class="{'form-field': true, 'textarea-field': true, 'mb-3': true, 'error': error || localError, ...classes }">
    <label :for="(id? id:name) + '-input'" class="form-label">{{name}}</label>
    <textarea v-model="localValue" @change="$emit('input', $event.target.value)" class="form-control" :id="(id? id:name) + '-input'" :placeholder="placeholder" />
    <div v-if="error || localError" class="alert alert-danger" role="alert">
      {{error || localError}}
    </div>
  </div>
</template>
<script>
export default {
  name: "TextArea",
  props: {
    value: String,
    name: String,
    id: String,
    error: String,
    placeholder: String,
    required: Boolean,
    fieldName: String,
    classes: Object
  },
  data () {
    return {
      localValue: this.value,
      localError: (this.required && this.value=='')? '* This field is required' : ''
    }
  },
  watch: {
    value (val) {
      this.localValue = val
    },
    localValue () {
        if (this.required && this.localValue === '') {
          this.localError = '* This field is required'
        }
        else {
          this.localError = ''
        }
      },
      localError (val) {
        this.$emit('localerror', {error: val, fieldName: this.fieldName})
      }
  },
  created () {
    this.$emit('localerror', {error: this.localError, fieldName: this.fieldName})
  }
}
</script>
<style>
textarea.form-control {
    border-radius: 15px;
    padding: 25px;
    height: 300px;
    display: flex;
}
.error textarea.form-control {
    border-color: #c88;
    background: #fffefe;
    outline: none;
}
.error  .form-label {
    color: #a55;
}

.textarea-field .form-label {
    padding-left: 5px;
    font-size: 1.2em;
    margin-top: 1em;
}
.textarea-field.style-A .form-label {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
}
.textarea-field.style-A textarea{
  border-radius: 10px;
  height: 200px;
  padding: 10px;
  font-size:12px;
}
</style>
