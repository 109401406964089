<template>
  <div :class="{dark: dark, 'city-query-wrapper': true,'text-input-field': true, 'mb-3': true, 'form-floating': floating, 'error': error || localError, ...classes }">
    <label v-if="!floating && name" :for="(id? id:name) + '-input'" class="form-label label-pre">{{name}}</label>
    <input
        :id="(id? id:name) + '-input'"
        :placeholder="floating? name: ''"
        v-model="cityquery"
        type="text"
        class="form-control"
        @focus="focus"
        @blur="blur"
        @keyup="autocomplete_city"
        autocomplete="none"
        >
      <div
        class="options"
        v-if="state=='autocomplete' && autocompleteOptions.length > 0"
        >

        <span
          v-for="(option, i) in autocompleteOptions"
          :key="i"
          @click="select_city(option)"
          class="option"
          >
            {{option.city}}, {{option.state_name}}
        </span>
      </div>
    <label v-if="floating" :for="(id? id:name) + '-input'" class="form-label">{{name}}</label>
    <div v-if="error || localError" class="alert alert-danger" role="alert">
      {{error || localError}}
    </div>
  </div>
</template>
<script>
// Fetch compatibility
import 'whatwg-fetch'
const IS_REQUIRED = '* This field is required'
export default {
    name: 'CityQuery',
    props: {
      value: String,
      name: String,
      floating: Boolean,
      id: String,
      error: String,
      type: String,
      placeholder: String,
      required: Boolean,
      fieldName: String,
      classes: Object,
      dark: Boolean
    },
    data () {
        return {
            cityquery: this.value,
            autocompleteOptions: [],
            featuredCities: [],
            state: "closed",
            localError: (this.required && this.value=='')? IS_REQUIRED : ''
        };
    },
    methods: {
        autocomplete_city () {
            let self = this
            if (this.cityquery.length >= 3 && this.cityquery.length < 100) {
                fetch("/cityquery?q=" + this.cityquery)
                        .then(response => response.json())
                        .then( (data) => {
                          self.autocompleteOptions = data
                          self.state="autocomplete"
                        });

            } else {
                this.autocompleteOptions = [];
                self.state="optionsMainScreen"
            }
        },
        clear_city () {
          this.state="closed"
          this.autocompleteOptions = [];
        },
        select_city (city) {
            let cityquery = city.city + ", " + city.state_name
            this.clear_city()
            this.clean_value = false
            this.$emit('input', cityquery)
        },
        focus () {
          if (self.timer) {
            clearTimeout(self.timer)
          }
          this.state = 'optionsMainScreen'
        },
        blur () {
          let self = this
          if (this.state !== "autocomplete")
            self.timer = window.setTimeout( () => {
              self.state = "closed"
              self.timer = null
              if (self.clean_value) {
                self.cityquery = ''
                if (self.required)
                  self.localError = IS_REQUIRED
              }
            }, 200 )
          self.clean_value = true
        }
    },
    watch : {
      value (val) {
        this.cityquery = val
      },
      cityquery (val, oldVal) {
        console.log('watch citiquery', val, oldVal, this.required)
        if (this.required && this.localValue === '') {
          this.localError = IS_REQUIRED
        }
        else {
          this.localError = ''
        }
      },
      localError (val) {
        this.$emit('localerror', {error: val, fieldName: this.fieldName})
      }
    },
    created () {
      let self = this
      fetch("/featured_cities")
              .then(response => response.json())
              .then( (data) => {
                self.featuredCities = data
              });

    }
}
</script>
<style scoped>

.city-query-wrapper {
    position: relative;
}
.options {
    position: absolute;
    top: 100%;
    left: 14px;
    background: white;
    min-width: 250px;
    border: 1px solid #ddd;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    z-index: 2;
}
.options > .option {
    border-bottom: 1px solid #ddd;
    padding: 0.5em;
    cursor: pointer;
    text-decoration: none;
    color: black;
    display: block;
}
.options > .option:hover {
    background: #efefef;
}

h5 {
  font-weight: lighter;
  font-size: 14px;
  margin: 0 0 10px 0;
}

.main-screen {
  padding: 0.7em 0.5em;
}

.dark .form-control {
  background: #222;
  color: white;
  border: 0;
  outline: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

@media(max-width: 770px) {
    .options {
        top: 80px;
    }
}

</style>
