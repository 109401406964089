<template>
  <div :class="{...classes}">
    <slot></slot>
    <span v-if="stringContent">{{stringContent}}</span>
  </div>
</template>
<script>
export default {
  name: "Div",
  props: {
    classes: Object,
    stringContent: String
  },
  data () {
    return { localValue: this.value };
  },
  watch: {
      value (val) {
          this.localValue = val;
      }
  }
}
</script>
<style>
div.title {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0px 20px 0px;
  line-height: 1.4em;
}
</style>
