<template>
  <div :class="{'scale-input-field': true, 'dark': dark, 'mb-3': !classes['style-A'], ...classes }">
    <label v-if="name" :for="(id? id:name) + '-input'" class="form-label label-pre">{{name}}</label>
    <span class="value">{{localValue}} </span>
    <input
        v-model="localValue"
        @change="$emit('input', $event.target.value)"
        type="range"
        :min="min || 1"
        :max="max || 10"
        class="form-control scale-input"
        :id="(id? id:name) + '-input'"
        :name="fieldName || name || id">
  </div>
</template>
<script>
export default {
  name: "ScaleField",
  props: {
    value: String,
    name: String,
    dark: Boolean,
    id: String,
    fieldName: String,
    classes: {type: Object, default: () => { return {} }},
    min: String,
    max: String,
    defaultValue: String
  },
  data () {
    return {
      localValue: this.value || this.defaultValue
    }
  },
  watch: {
    value (val) {
      this.localValue = val
    }
  }
}
</script>
<style>
.scale-input-field {
  position: relative;
}
.scale-input-field .form-label.label-pre {
  padding-left: 5px;
  font-size: 1.2em;
  margin-top: 1em;
}
.scale-input-field.error  .form-label {
  color: #c77;
}
.scale-input-field.style-A {
  margin-bottom: 5px;
}
.scale-input-field.style-A .form-label.label-pre {
  font-size: 14px;
  margin-top: 0.75rem;
  margin-bottom:0.5rem;
}
.scale-input {
  padding: 0px;
  height: 2px;
  border: 0;
  background: #999;
}
.dark .scale-input {
  background: white;
}
.scale-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 20px;
  background: #FFD300;
  cursor: pointer;
}
.scale-input::-moz-range-thumb {
  width: 10px;
  height: 20px;
  background: #FFD300;
  cursor: pointer;
}
.scale-input-field .value {
  display: block;
  margin-bottom: 1em;
}
</style>
