<template>
  <div :class="{'text-input-field': true, 'dark': dark, 'mb-3': !classes['style-A'], 'form-floating': floating, 'error': error || localError, ...classes }">
    <label v-if="!floating && name" :for="(id? id:name) + '-input'" class="form-label label-pre">{{name}}</label>
    <input
        :placeholder="floating? name: ''"
        v-model="localValue"
        @change="$emit('input', $event.target.value)"
        type="text"
        class="form-control"
        :id="(id? id:name) + '-input'">
    <label v-if="floating" :for="(id? id:name) + '-input'" class="form-label">{{name}}</label>
    <div v-if="error || localError" class="alert alert-danger" role="alert">
      {{error || localError}}
    </div>
  </div>
</template>
<script>
export default {
  name: "TextInput",
  props: {
    value: String,
    name: String,
    floating: Boolean,
    dark: Boolean,
    id: String,
    error: String,
    type: String,
    placeholder: String,
    required: Boolean,
    fieldName: String,
    classes: {type: Object, default: () => { return {} }}
  },
  data () {
    return {
      localValue: this.value,
      localError: (this.required && this.value=='')? '* This field is required' : ''
    };
  },
  watch: {
    value (val) {
      this.localValue = val
    },
    localValue () {
      if (this.required && this.localValue === '') {
        this.localError = '* This field is required'
      }
      else {
        this.localError = ''
      }
    },
    localError (val) {
      this.$emit('localerror', {error: val, fieldName: this.fieldName})
    }
  },
  created () {
    if (!this.localValue)
      this.$emit('localerror', {error: this.localError, fieldName: this.fieldName})
  }
}
</script>
<style>
.text-input-field input[type=text].form-control {
    border-radius: 25px;
    padding-left: 25px;
}
.text-input-field .form-label.label-pre {
    padding-left: 5px;
    font-size: 1.2em;
    margin-top: 1em;
}
.text-input-field.error input.form-control {
    border-color: #c88;
    background: #fffefe;
    border: 0;
    outline: 0;
    border-radius: 0;
    -webkit-appearance: none;
}
.text-input-field.error  .form-label {
    color: #c77;
}
.text-input-field.style-A {
  margin-bottom: 5px;
}
.text-input-field.style-A input[type=text].form-control {
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
}
.text-input-field.dark input[type=text].form-control {
  background: #222222;
  color: white;
  outline: 1px solid #888;
}
.text-input-field.style-A .form-label.label-pre {
  font-size: 14px;
  margin-top: 0.75rem;
  margin-bottom:0.5rem;
}
.text-input-field.inline input[type=text].form-control {
  width: auto;
  display: inline-block;
}
.text-input-field.inline {
  display: inline-block;
  margin-right: 1em;
}
</style>
