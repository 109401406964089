<template>
  <div :class='{"ranking-field": true, dark: dark, "mt-4": true, "mb-3": !classes || !classes["style-A"]}'>
    <label v-if="name" class="form-label label-pre">{{name}}</label>
    <div :class='{"mt-1":true,...classes}'>
      <div class="option" v-for='(option, key) in localValue' :key='key'>
        <button class="move-up" @click="moveUp(key)"><span></span><span></span></button>
        <button class="move-down" @click="moveDown(key)"><span></span><span></span></button>
        <span>{{option}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RankingField",
  props: {
    classes: Object,
    options: Array,
    value: Array,
    fieldName: String,
    dark: Boolean,
    name: String
  },
  data () {
    return {
      localValue: this.value?[...this.value]:[...this.options]
    };
  },
  watch: {
      localValue(val) {
        this.$emit('input', val)
      }
  },
  methods: {
    moveUp(key) {
      if (key==0) return
      let option = this.localValue.splice(key, 1)
      this.localValue.splice(key-1, 0, option[0])
    },
    moveDown(key) {
      if (key==this.localValue.length-1) return
      let option = this.localValue.splice(key, 1)
      this.localValue.splice(key+1, 0, option[0])
    }
  },
  created () {
  }
}
</script>
<style>
.ranking-field .move-up, .ranking-field .move-down {
  width: 35px;
  height: 20px;
  padding: 0px;
  position: relative;
  background: #eee;
  outline: none;
  border: 0;
  border-radius:5px;
  margin-right: 4px;
  vertical-align: middle;
}
.ranking-field .move-up:hover, .ranking-field .move-down:hover {
  background: #ccc;
}
.ranking-field .move-up span, .ranking-field .move-down span{
  background: black;
  transform: rotate(-30deg);
  width: 11px;
  height: 1px;
  position: absolute;
  top: 7px;
  left: 8px;
}
.ranking-field .move-up span:last-child {
  transform: rotate(30deg);
  left: 17px;
}
.ranking-field .move-down span:first-child {
  transform: rotate(30deg);
  left: 8px;
  top: 10px;
}
.ranking-field .move-down span:last-child {
  transform: rotate(-30deg);
  left: 17px;
  top: 10px;
}
.ranking-field  .option {
  background: #f8f8f8;
  margin: 4px;
  text-align: left;
  padding: 5px;
  border-radius: 3px;
}
.ranking-field  .option > span {
  margin-left: 1em;
  color: black;
}
.ranking-field  .option:hover {
  background: #f5f5f5;
}
</style>
