const coreapi = window.coreapi;
const settings = window.FRONTEND_SETTINGS

let auth = new coreapi.auth.SessionAuthentication({
    csrfCookieName: 'csrftoken',
    csrfHeaderName: 'X-CSRFToken',
});
let client = new coreapi.Client({auth: auth});

function getDocumentData(dataSource) {
    const container = document.getElementById(dataSource);
    if (!container) return null;
    const dataset = JSON.parse(container.text);
    return dataset;
}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function getBase64(file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function () {
            var fileEncoded = reader.result;
            resolve(fileEncoded);
        };

        reader.onerror = function (error) {
            reject(error);
        };
    });
}

function goToURL(url) {
    document.location = url;
}

export {
  client,
  getCookie,
  getBase64,
  getDocumentData,
  goToURL,
  settings
}
