<template>
  <div :class="{ 'keywords-field': true, 'dark': dark }">
    <label v-if="name" class="form-label label-pre">{{name}}</label>
    <div @click="focusInput" :class="{'keywords-field-input': true, ...classes}">
      <label v-for="(item, key) in items" :key="key" class="keyword-item">{{item}} <b @click="deleteItem(key)">⨯</b></label>
      <input ref="inputField" v-on:keyup.enter="addNewItem" v-on:keyup="keyUp" @focus="focus" type="text" v-model="newItem" @blur="blur" />
    </div>
    <div
      class="options"
      v-if="state=='autocomplete' && options.length > 0"
      >
      <span
        v-for="(option, i) in options"
        :key="i"
        @click="select(option)"
        class="option"
        >
        {{option}}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KeywordsFIeld',
  components: {},
  props: {
    classes: Object,
    dark: Boolean,
    limitToOptions: Boolean,
    name: String,
    options: Array,
    value: String,
  },
  data () {
    return {
      internalValue: this.value || '',
      newItem: '',
      state: 'closed'
    }
  },
  computed: {
    items () {
      return this.internalValue.split(',').filter((item) => { return item.length > 0  })
    }
  },
  methods: {
    select(item) {
      console.log(item)
      if (this.items.indexOf(item) == -1)
        this.sendInput(this.internalValue + ',' + item)
      this.newItem = ''
    },
    addNewItem() {
      if (this.newItem.length > 1) {
        if (
            this.items.indexOf(this.newItem) == -1 && (
              !this.limitToOptions ||
              (this.limitToOptions && this.options.indexOf(this.newItem) != -1)
            )
          )
          // replace commas with html entity string to avoid conflict with the comma separation of the values, escaping
          this.sendInput(this.internalValue + ',' + this.newItem.replace(/,/g, '&comma;'))
        this.newItem = ''
      }
    },
    focusInput() {
      this.$refs['inputField'].focus()
    },
    deleteItem(key) {
      let items = [...this.items]
      items.splice(key, 1)
      this.sendInput(items.join(','))
    },
    sendInput(value) {
      this.internalValue = value
      this.$emit('input', value)
    },
    keyUp() {
      if (this.newItem.length>0) {
        this.state = 'autocomplete'
      }
    },
    focus() {
      this.state = 'autocomplete'
    },
    blur() {
      let self = this
      if (this.state == "autocomplete")
        self.timer = window.setTimeout( () => {
          self.state = "closed"
          self.timer = null
        }, 200 )
      self.clean_value = true
    }
  }
}
</script>
<style>
.keywords-field {
  position: relative;
}
.keywords-field .form-label.label-pre {
    padding-left: 5px;
    font-size: 1.2em;
    margin-top: 1em;
}
.keywords-field-input {
  background: white;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  text-align: left;
}
.keywords-field-input input {
  border: 0;
  inset: 0;
}
.keywords-field-input label {
  background: #eee;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 3px;
  margin: 4px;
}
.dark .keywords-field-input {
  background: #333;
  border-color: white;
  color: white;
}
.dark .keywords-field-input label {
  background: #333;
  color: white;
}
.dark .keywords-field-input input {
  background: #333;
  color: white;
}

.keywords-field-input label b {
  font-size: 15px;
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
}
.keywords-field-input input:focus-visible {
  outline: none;
}
.keywords-field .options {
    position: absolute;
    top: 100%;
    left: 14px;
    background: white;
    min-width: 250px;
    border: 1px solid #ddd;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    z-index: 2;
}
.keywords-field .options > .option {
    border-bottom: 1px solid #ddd;
    padding: 0.5em;
    cursor: pointer;
    text-decoration: none;
    color: black;
    display: block;
}
.keywords-field .options > .option:hover {
    background: #efefef;
}


</style>
