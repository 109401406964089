<template>
  <div :class='{"form-filler":true, ...extraClasses}'>
    <div class="alert-banner" v-if="community_fill">Notice: You are filling this form on behalf of a community</div>
    <template v-if="form">
      <SimpleFlexColumn
        :full='true'
        :responsive="true"
        :classes='{"slide": true, "selected": state=="ready" && index==currentSlide }'
        v-for="(slide, index) in form.slides"
        :key="index"
      >
        <header
          :class='{"slide-header": true}'
          :style="{ backgroundImage: 'url(' + ( (slide.header && slide.header.image)? slide.header.image : survey.cover || '') + ')'}" >
        </header>
        <div :class='{"form": true, "primary": true}'>
          <h1 v-if="slide.header && slide.header.title">{{slide.header.title}}</h1>
          <div class="form-section" v-if="slide.component">
            <component v-if='slide.component' v-bind='slide.props' :is='slide.component' v-on:input='slideInputHandler' />
          </div>
          <div class="form-section" v-if="slide.form">
            <div class="field" v-for="(field, findex) in slide.form" :key="findex">
              <component
                :is="fields.types[field.type]"
                v-bind="field.props"
                v-model="formResponse[field.props.outputProperty || field.props.name]"
                v-if="!field.metadata"
                v-on="field.events"
                :dark="dark"
              />
            </div>
          </div>
          <div class="form-section">
            <p v-if="currentSlide < form.slides.length - 1  ">
              <button class="btn btn-yellow shadow" @click="next">Next</button>
            </p>
            <p v-if="currentSlide == form.slides.length - 1  ">
              <button class="btn btn-yellow shadow" @click="sendForm">Send</button>
            </p>
            <p>
              <button v-if="currentSlide > 0" class="mt-3 btn btn-light shadow" @click="previous">Go Back</button>
            </p>
          </div>
        </div>
      </SimpleFlexColumn>
      <SimpleFlexColumn :full='true' :responsive="true" :classes='{"slide": true, "selected": state=="done"}'>
        <header
          :class='{"slide-header": true}'
          :style="{ backgroundImage: 'url(' + survey.cover + ')'}" >
        </header>
        <div class="primary form">
          <h1>Your response was sent</h1>
        </div>
      </SimpleFlexColumn>
      <SimpleFlexColumn :full='true' :responsive="true" :classes='{"slide": true, "selected": state=="requestLogin"}'>
        <header
          :class='{"slide-header": true}'
          :style="{ backgroundImage: 'url(' + survey.cover + ')'}" >
        </header>
        <div class="primary form">
          <h3 class="my-5">Login or Sign up to submit your answers:</h3>
          <p>
            <a class="btn btn-yellow shadow btn-full" href="/login">Log in</a>
          </p>
          <p>
            <a class="btn btn-white shadow btn-full" href="/signup">Sign up</a>
          </p>
        </div>
      </SimpleFlexColumn>
      <SimpleFlexColumn :full='true' :responsive="true" :classes='{"slide": true, "selected": state=="redirect"}'>
        <header
          :class='{"slide-header": true}'
          :style="{ backgroundImage: 'url(' + survey.cover + ')'}" >
        </header>
        <div class="primary form">
          <h4>Redirecting ... </h4>
        </div>
      </SimpleFlexColumn>
    </template>
  </div>
</template>
<script>
import SimpleFlexRow from '../components/SimpleFlexRow'
import SimpleFlexColumn from '../components/SimpleFlexColumn'
import fields from '../components/fields.js'
import { getCookie } from '../client'
import { ClientPendingActions } from '../pending_actions'
import slideComponents from '../components/slideComponents.js'

const csrftoken = getCookie('csrftoken');
const pendingActions = new ClientPendingActions()

export default {
  name: 'SurveyRender',
  props: {
    slug: String,
    username: String,
    community_fill: String,
    redirect: String,
    sendInsteadOfEmit: Boolean
  },
  components: {
      SimpleFlexColumn, SimpleFlexRow
  },
  data () {
    return {
      fields: fields,
      formResponse: {},
      survey: null,
      form: null,
      currentSlide: 0,
      data: {},
      errors: {},
      state: 'initializing',
      dark: false
    }
  },
  computed: {
    extraClasses() {
      if (this.dark) {
        return {'dark-theme': true}
      }
      return {'light-theme': true}
    }
  },
  methods: {
    async sendForm() {
      if (!this.sendInsteadOfEmit) {
        this.$emit("input", this.formResponse);
        return;
      }
      let formData = new FormData()
      formData.append('response', JSON.stringify(this.formResponse))
      if (this.community_fill) {
        formData.append('community', this.community_fill)
      }
      if (!this.form.configuration.anonymousResponses && !this.username) {
        pendingActions.deferAction({
          id: this.survey.slug,
          data: this.formResponse,
          metadata: {
            community_fill: this.community_fill,
            survey: this.survey
          },
          created: Date.now(),
          expires: new Date(Date.now()+1000*60*60), // one hour to log in
          type: 'SubmitSurveyResponse'
        }, true)
        this.state = 'requestLogin'
      }
      else {
        let result = await fetch("/api/account/survey/" + this.survey.slug + "/response/", {
            headers: {
                "X-CSRFToken": csrftoken
            },
            body: formData,
            method: "POST"
        })
        if (result.status == 201) {
          if (this.redirect) {
            this.state = 'redirect'
            document.location = this.redirect.replace('//', '')
          }
          else {
            this.state = 'done'
          }
        }
      }
    },
    next() {
      let slide = this.form.slides[this.currentSlide]
      var anyError = false
      slide.form.forEach( (field) => {
        if (this.errors[this.currentSlide] && this.errors[this.currentSlide][field.props.name])
          anyError |= true
      })
      if (anyError) return

      if (this.currentSlide < this.form.slides.length )
        this.currentSlide += 1
      window.scroll(0, 0)
    },
    previous() {
      if (this.currentSlide > 0)
        this.currentSlide -= 1
    },
    prepareForm(formData) {
      var form = JSON.parse(formData);
      form.slides = form.slides.map((slide) => {
        if (slide.componentReference)
          slide.component = slideComponents[slide.componentReference];
        if (slide.header) {
          slide.header.title = slide.header.title? slide.header.title.replaceAll("{{username}}", this.username) : null;
        }

        slide.form = slide.form.map((item) => {
          item.props.title = item.props.title? item.props.title.replaceAll("{{username}}", this.username) : null;
          item.props.content = item.props.content? item.props.content.replaceAll("{{username}}", this.username) : null;
          item.props.name = item.props.name? item.props.name.replaceAll("{{username}}", this.username) : null;
          return item;
        });
        return slide;
      });
      return form;
    },
    slideInputHandler (key, value) {
      this.formResponse[key] = value;
    }
  },
  async mounted () {
    this.state = 'loading'
    let response = await fetch("/api/survey/" + this.slug + "/", {
      method: "GET"
    })
    let data = await response.json()
    this.survey = data;
    this.form = this.prepareForm(this.survey.questions);
    this.state = 'ready';
    if (window.innerWidth && window.innerWidth < 600) {
      this.dark = true;
    }
    else {
      this.dark = this.form.configuration? this.form.configuration.theme == 'Dark' : true
    }
  }
}
</script>
<style scoped>
.form-filler{
  background: #222222;
  color: white;
}
.form-filler h1 {
  max-width: 600px;
  margin: auto;
}
header.slide-header{
  background: #222;
  color: white;
  justify-content: center;
  height: 30vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
header.slide-header:before {
  position: absolute;
  content: ' ';
  display: block;
  top: 0px;
  left:0px;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0) linear-gradient(to bottom, rgba(34, 34, 34, 0) 10%, rgba(34, 34, 34, .1) 40%, rgba(34, 34, 34, .5) 75%, rgba(34, 34, 34, 1) 100%) repeat scroll 0 0;

}
.form.primary {
  padding: 2em;
  justify-content: center;
  max-height: 100%;
}
.slide {
  display: none;
  min-height: 100vh;
}
.slide.selected {
  display: flex;
}
.flex-child {
  display: flex;
  flex: 1;
}
.form-section {
  max-width: 400px;
  padding: 10px;
  text-align: center;
  margin: auto;
}

@media(max-width: 750px) {
  .header.primary {

  }
}
</style>
<style>
.form-filler.light-theme .column-content{
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: row;
  min-height: 100vh;
}
.form-filler.light-theme header.slide-header {
  height: 100vh;
  position: relative;
  display: flex;
  flex: 1;
  max-width: 50vw;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
}
.form-filler.light-theme .form.primary {
  background: white;
  color: black;
  min-width: 50vw;
}

.form-filler.light-theme  header.slide-header:before {
  display: none;
}

.alert-banner {
  background: #fcd405;
  color: black;
  text-align: center;
  padding: 0.3em;
}
</style>
