<template>
  <div class="comm-card">
    <div v-if="community" class="card d-flex shadow align-items-start ">
        <a class="img-link" :href="community.url"><img :src="community.cover" class="card-img-top"></a>
        <div class="card-body">
            <h5 class="card-title">
              {{ community.title }}
            </h5>
            <h4>{{community.university_name || community.city}}</h4>
            <p>{{community.small_description}}</p>

        </div>
        <div class="p-3 mt-auto text-center align-self-stretch">
            <a class="btn btn-dark"  :href="community.url">Visit your community page</a>
        </div>
    </div>
    <div v-if="!community" class="p-5 text-center">
      <Spinner />
    </div>
  </div>
</template>
<script>
import Spinner from '../components/Spinner'

export default {
  name: "CommunityCard",
  components: {Spinner},
  props: {
    community: Object
  },
  data() {
    return {
    }
  },
  methods: {

  },
  async mounted () {
  }
}
</script>
<style>
.comm-card {
    margin-bottom: 100px;
    width: 100%;
    height: auto;
    min-height: none;
    max-width: 400px;
    margin: 20px auto;
}
.comm-card .card {
    margin: 0px 5px;
    width: 100%;
    height: auto;
    min-height: 300px;
    border-radius: 20px;
}
.comm-card .cards div .card img, .featured .cards .card  {
    border-radius: 0;
}

</style>
