var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'simpleflex-row-responsive': _vm.responsive,
    'simpleflex-row': true,
    'simpleflex-row-full': _vm.full,
    'simpleflex-row-fixed': _vm.fixed,
    ... _vm.classes
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }