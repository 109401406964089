<template>
  <div :class="{'text-section': true,...classes}">
    <h3>{{title}}</h3>
    <div class="text-content">{{content}}</div>
  </div>
</template>
<script>

export default {
  name: "TextSection",
  props: {
    title: String,
    content: String,
    classes: Object
  },
  data () {
    return {};
  },
  watch: {
  }
}
</script>
<style>
.text-section h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0px 20px 0px;
  line-height: 1.4em;
}
.text-section .text-content {
  white-space: break-spaces;
  text-align: left;
  font-size: 14px;
  margin-bottom: 1em;
}

</style>
