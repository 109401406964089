<template>
  <div class="select-field">
    <label v-if="name" class="form-label label-pre">{{name}}</label>
    <select v-model="localValue" :class='{dark: dark, "error": error || localError, "form-select":true, "mb-3": !classes || !classes["style-A"], "mt-1":true,...classes}'>
      <option v-for='(option, key) in options' :key='key' :value='option.value? option.value: option'>
        {{option.title? option.title: option}}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "Select",
  props: {
    classes: Object,
    options: Array,
    value: String,
    default: String,
    none: String,
    required: Boolean,
    fieldName: String,
    error: String,
    dark: Boolean,
    name: String
  },
  data () {
    return {
      localValue: this.value? this.value : this.default,
      localError: (this.required && this.value=='')? '* This field is required' : ''
    };
  },
  watch: {
      value (val) {
          this.localValue = val == ''? this.none: val;
      },
      localValue(val) {
        this.$emit('input', val == this.none? '' : val)
        if (this.required && this.localValue === this.none) {
          this.localError = '* This field is required'
        }
        else {
          this.localError = ''
        }
      },
      localError (val) {
        this.$emit('localerror', {error: val, fieldName: this.fieldName})
      }
  },
  created () {
    if (this.required && this.localError)
      this.$emit('localerror', {error: this.localError, fieldName: this.fieldName})
  }
}
</script>
<style>

.select-field .form-label.label-pre {
    padding-left: 5px;
    font-size: 1.2em;
    margin-top: 1em;
}
select.form-select {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  font-size: 18px;
}
select.form-select.dark {
  background: #222;
  color: white;
}
select.error {
  border: 1px solid #c88;
  background: #fffefe;
  color: #c77;
}
select.error:focus {
  color: black;
}
select.form-select.style-A {
  padding: 0.2rem 1rem;
  font-size: 13px;
  border-radius: 5px;
}
</style>
